// libraries
import { useMutation } from 'react-query';
import AsyncStorage from '@react-native-async-storage/async-storage';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import { useAppSelector } from '@redux/store';
import { selectSavedCoursesByDeliveryId } from '@screens/dashboard/selectors';
import request from '@utils/request';
import { ServerResponse, DeliverySaveBodyCourse } from './types';
import { useUser } from '@context/UserProvider';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';
import { APP_VERSION_KEY } from '@utils/keys';
import { err } from 'react-native-svg/lib/typescript/xml';
import alert from '@utils/alert';

export interface SaveSurveyRequest {
  survey: DeliverySaveBodyCourse[];
}

export type SaveDeliveryResponse = ServerResponse<null>;

export const useSaveSurvey = (
  savedSurvey,
  {
    onError,
    onSuccess,
  }: {
    onSuccess?: (res: SaveDeliveryResponse) => void;
    onError?: (res: ServerResponse<unknown>) => void;
  } = {},
) => {
  // variables
  const { triggerError } = useErrorHandler();

  const { user } = useUser();
  const { delivery } = useGetDeliveryCourseAndRiders();
  const savedSurvey1 = useAppSelector(state => selectSavedCoursesByDeliveryId(state, delivery?.id));

  // request
  const query = useMutation<
    SaveDeliveryResponse,
    ServerResponse<unknown> | undefined,
    SaveSurveyRequest
  >(['saveSurvey'], async ({ survey }: SaveSurveyRequest) => {
    try {
      const currentAppVersion = await AsyncStorage.getItem(APP_VERSION_KEY);
      const saveSurvey = await request<SaveDeliveryResponse>({
        method: 'post',
        url: `/deliveries/storeSurvey`,
        data: {
          instructor: user.id,
          appTimestamp: Date.now(),
          appVersion: currentAppVersion,
          delivery: { courses: savedSurvey1, id: delivery.id },
        },
      });

      onSuccess && onSuccess(saveSurvey);
      return saveSurvey;
    } catch (error) {
      onError && onError(error);
      if (error && error?.code === 422 && error?.message) {
        alert('Error', error?.message, [
          {
            text: 'Close',
            style: 'cancel',
          },
        ]);
      } else {
        triggerError({ error });
      }
    }
  });

  // return
  return query;
};
