// libraries
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/native';

// components
import { CustomModal, SelectInput } from '@components/molecules';
import { DivColumn, Input, SmallButton, SpacerColumn } from '@components/atoms';

// misc
import { DeliveryDatumRider } from '@screens/dashboard/types';
import { genericStyles } from '@styles/genericStyles';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectRiderKitCheckText } from '@screens/dashboard/selectors';
import { deliveriesSlice } from '@screens/dashboard/slice';
import { useSubmitSavedDelivery } from '@hooks/useSubmitSavedDelivery';
import alert from '@utils/alert';

// types
export type BikeAndKitModalProps = {
  isVisible: boolean;
  onClose?(): void;
  riders?: DeliveryDatumRider[];
  deliveryId: number;
};

type FormType = { riderId: string; notes: string };

export const BikeAndKitModal: React.FC<BikeAndKitModalProps> = ({
  isVisible,
  onClose,
  riders,
  deliveryId,
}) => {
  // variables
  const defaultRiderId = riders[0].id;

  const { submit, isLoading } = useSubmitSavedDelivery('Bike kit text updated successfully!', {
    onSuccess: onClose,
  });
  const { handleSubmit, control, setValue, watch, getValues } = useForm<FormType>({
    defaultValues: {
      riderId: defaultRiderId.toString(),
    },
  });
  const dispatch = useAppDispatch();
  const selectedRiderId = parseInt(watch('riderId'));
  const selectedKitCheckText = useAppSelector(state =>
    selectRiderKitCheckText(state, deliveryId, selectedRiderId),
  );
  const notes = watch('notes');

  const modifyRiders = useMemo(
    () => riders.map(r => ({ label: r.name, value: r.id.toString() })),
    [riders],
  );

  // hooks
  useEffect(() => {
    dispatch(
      deliveriesSlice.actions.setSaveDeliveryRider({
        kitCheckText: notes,
        deliveryId,
        riderId: selectedRiderId,
      }),
    );
  }, [notes]);

  useEffect(() => {
    setValue('notes', selectedKitCheckText);
  }, [selectedRiderId]);

  useEffect(() => {
    if (defaultRiderId !== 0) setValue('riderId', defaultRiderId.toString());
  }, [riders]);

  useEffect(() => {
    setValue('notes', '');
  }, [isVisible]);

  // functions
  const onSubmit = () => {
    submit();
  };

  // Handle Close button of popup
  const handleClose = () => {
    if (getValues('notes') && getValues('notes').length > 0) {
      alert('Cycle & Kit Check', 'You have unsaved data, would you like to save it now?', [
        {
          text: 'Ok',
          onPress: () => {
            handleSubmit(onSubmit)();
          },
        },
        {
          text: 'Cancel',
          onPress: () => onClose(),
          style: 'cancel',
        },
      ]);
    } else {
      onClose();
    }
  };

  // renders
  return (
    <CustomModal isVisible={isVisible} title="Cycle & Kit Check" onClose={handleClose}>
      <Content>
        <DivColumn style={{ width: '100%', height: 80 }}>
          <SelectInput
            name="riderId"
            control={control}
            data={modifyRiders}
            onChangeValue={setValue}
            title=""
            placeHolder="Choose Rider"
            sheetTitle="Select rider"
            rules={{ required: true }}
          />
        </DivColumn>
        <SpacerColumn size={2} />
        <Input
          title="Please provide feedback regarding cycle defects or other equipment issues. This information will be sent directly to the parent"
          textColor="error"
          fontSize={14}
          name="notes"
          control={control}
          multiline
          numberOfLines={30}
          containerStyle={{ flex: 1 }}
          rules={{ required: true }}
        />
        <SpacerColumn size={3} />
        <SmallButton title="Save" isLoading={isLoading} onPress={handleSubmit(onSubmit)} />
        <SpacerColumn size={1} />
      </Content>
    </CustomModal>
  );
};

const Content = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.fill,
  padding: layout.padding_x2,
}));
