// libraries
import React from 'react';
import styled from 'styled-components/native';

// components
import { BigButton, CustomText, DivColumn, Heading, SimpleButton, SpacerColumn } from '@components/atoms';

// misc
import { genericStyles } from '@styles/genericStyles';
import { useUser } from '@context/UserProvider';
import { useAppSelector } from '@redux/store';
import { selectSystemDataDeliveries } from '@screens/dashboard/selectors';

export const AppHelpScreen = () => {
  // variables
  const { user } = useUser();
  const systemData = useAppSelector(selectSystemDataDeliveries);

  // returns
  return (
    <Container>
      <Heading title={`Hello ${user?.first_name}, this is your admin area`} />

      <Content>
        <DivColumn>
          <SimpleButton title="Link Admin" onPress={() => window.open('https://link.bikeability.org.uk/', '_blank')}/>
          <SpacerColumn size={1} />
          <SimpleButton title="App Onboarding Pack Download" onPress={() => window.open('https://www.bikeability.org.uk/wp-content/uploads/2024/06/Training-Provider-Onboarding-Pack-2024-PDF.pdf', '_blank')}/>
        </DivColumn>
        <DivColumn>
          <CustomText style={{ color: 'black', textAlign: 'center' }} font="bodyBold">
            If you need further support then please complete a the support request form below.
             This will be sent to our system admin team and somebody will be in touch regarding your query.
          </CustomText>
          <SpacerColumn size={4} />
          <BigButton 
            text="Raise a technical issue" 
            onPress={() => window.open('https://forms.office.com/pages/responsepage.aspx?id=ocfH1WNnzE-iRZL4IdM8ifYPtlod3W9IoyhYEnm7j9hURU0wRDhLWkhHMk41MVA2MjNYM0lWOUVLWCQlQCN0PWcu&route=shorturl', '_blank')}
          />
          <SpacerColumn size={4} />
          <Heading title={`Last date update: ${systemData.lastDataUpdate}`} />
          <SpacerColumn size={1} />
          <Heading title={`App Version No ${systemData.appVersion}`} />
        </DivColumn>
      </Content>
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const Content = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.fill,
  ...genericStyles.jcSb,
  padding: layout.contentPadding,
}));
